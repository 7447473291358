/*
    POINT D'ENTRÉE VARIABLES CSS BRIKS
    Placer le chemin du fichier dans le fichier _config.yml de LibDoc
*/

/*
    CORE
    Fonctionnalités principales 
    Inclure briks/_core.scss pour obtenir les fonctions helpers et les mixins.
    Aucune déclaration CSS n'est générée à partir de ce fichier.
*/
@import 'briks/core';
/*
    DESIGN TOKENS
    Fichier SASS contenant les design tokens du projet
*/
@import 'briks/settings/tokens/ucar';
/*
    CSS VARIABLES
    Requiert briks/_core.scss
    Ce fichier génère les variables CSS de briks
*/
@import 'briks/css-vars';